.table-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 2%;
    padding-top: 10px;
}

.table-heading h2 {
    font-weight: bold;
}

.table-heading div {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-heading .search-input {
    padding-inline: 10px;
    width: 80%;
    height: 40px;
    border-radius: 6px;
    border: 2px solid #007bff;
}

.table-heading .search-input:focus {
    outline: none;
}

.table-heading button {
    color: #fff;
    background: #000;
    padding: 5px 8px;
    border: none;
    border-radius: 6px;
}

.project-tables-container {
    margin: 10px 2%;
    border-radius: 6px;
    background: #fff;
    padding: 10px 10px 20px;
}

.page-item .page-link {
    background-color: transparent !important;
    color: #000 !important;
    transition: all .3s ease;
}

.pagination-active .page-link {
    color: white !important;
    background: #004fa4 !important;
}

.project-tables-container h5 {
    font-size: 20px;
    font-weight: 400;
}

.project-tables-container p {
    font-size: 14px;
}

.project-table {
    width: 100%;
    border-collapse: collapse;
}

.actions-td span {
    display: flex;
    align-items: center;
}

.project-table .icon-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    display: inline;
}

.project-table th {
    text-align: start;
    align-items: flex-start;
}

.project-table .max-td {
    width: 30%;
}

.project-table th,
.project-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.text-muted {
    color: #6c757d;
}

.registry-table-rows td {
    padding: 10px;
}

.pagination {
    gap: 10px;
}

.project-tables-container .pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    padding-inline: 0px 5%;
}

.project-tables-container .pagination-controls button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.project-tables-container .pagination-controls button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

.project-tables-container .pagination-controls button:hover:not(:disabled) {
    background-color: #0056b3;
}

.not-found {
    text-align: center;
    border: none !important;
    font-size: 22px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
}

.modal-overlay .button-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.modal-content h2 {
    margin-top: 0;
    text-align: center;
    font-weight: bold;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content form label {
    margin-bottom: 10px;
}

.modal-overlay .modal-input-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-item .page-link {
    background-color: transparent !important;
    color: #000 !important;
    transition: all .3s ease;
}

.pagination-active .page-link {
    color: white !important;
    background: #004fa4 !important;
}

.admin-profile-form .modal-input-div label {
    font-size: 14px;
    color: #6b6b6b
}

.admin-profile-form .modal-input-div {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.admin-profile-form .modal-input-div input,
.modal-overlay .modal-input-div textarea,
.modal-overlay .modal-input-div input {
    border: 1px solid black;
    border-radius: 6px;
    width: 65%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.admin-profile-form .modal-input-div input {
    width: 100%;
    background: #fff;
    height: 50px;
    border-color: #d4d4d4;
}

.admin-profile-form .btn-para {
    text-align: center;
}

.modal-overlay .modal-input-div textarea:focus,
.modal-overlay .modal-input-div input:focus {
    border-color: #007bff;
    outline: none;
}

.modal-overlay .button-div button {
    width: 45%;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
    margin-top: 10px;
}

.certificate-table {
    padding-right: 40px;
}

.view-reg-form {
    overflow-y: scroll;
    height: 80vh;
}

.view-reg-form::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
}

.view-reg-form::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 4px;
}

.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.registry-loader {
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #ffffff 90%, #ffffff00) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  margin-left: 5px;
  animation: registryLoader 1s steps(4) infinite;
}

@keyframes registryLoader {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}


@media (max-width: 540px) {
    .project-table {
        width: 150vw;
    }

    .project-table-scroll::-webkit-scrollbar {
        background-color: transparent;
        height: 8px;
    }

    .project-table-scroll::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 4px;
    }

    .model-inside-div {
        width: 90% !important;
    }
}

@media (max-width: 430px) {
    .project-table {
        width: 170vw;
    }

    .modal-overlay .modal-input-div {
        flex-direction: column;
        align-items: start;
    }

    .admin-profile-form .modal-input-div input {
        width: 55%
    }

    .modal-overlay .modal-input-div textarea,
    .modal-overlay .modal-input-div input {
        width: 95%;
    }

    .modal-content h2 {
        font-size: 30px;
    }

}

@media (max-width: 390px) {
    .project-table {
        width: 200vw;
    }

    .modal-overlay {
        padding-inline: 5%;
    }
}

@media (max-width: 320px) {
    .project-table {
        width: 240vw;
    }
}