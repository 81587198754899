.multiple-form-page .page-main-div {
    min-height: max-content;
}

.multiple-form-page .containers {
    min-height: max-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;
    padding-inline: 2%;
}

.multiple-form-page .card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px #0000001a;
    padding: 10px 20px;
    text-align: center;
    width: 23%;
    cursor: pointer;
}

.multiple-form-page .icon-container {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
}

.multiple-form-page .icon {
    width: 30px;
    height: 30px;
}

.multiple-form-page .text-container h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

@media (max-width: 768px) {
    .multiple-form-page .card {
        width: 32%;
    }
}

@media (max-width: 520px) {
    .multiple-form-page .card {
        width: 47%;
    }
}

@media (max-width: 450px) {
    .multiple-form-page .card {
        width: 95%;
    }
}