.payment-container {
    width: 80%;
    margin: auto;
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px #0000001a;
}

.payment-container h1 {
    font-size: 28px;
    font-weight: 600;
}

.payment-container .text-gray {
    color: #adb0c0;
    margin-bottom: 5%;
}

.payment-container .card-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 100px;
    flex-wrap: wrap;
}

.payment-container .card {
    background: #f8fafb;
    border: none;
    border-radius: 8px;
    padding: 5% 20px 2%;
    margin: 0 10px;
    transition: transform 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 30%;
}

.payment-container .card:hover {
    transform: translateY(-20px);
}

.payment-container .card h2 {
    font-size: 26px;
    font-weight: 600;
}

.payment-container .card .badges span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.payment-container .card .badges span {
    width: 80%;
    text-align: start;
    padding: 0px;
}

.payment-container .price {
    font-size: 34px;
    font-weight: 600;
    color: #007bff;
}

.payment-container .small {
    font-size: 22px;
}

.payment-container .year {
    font-size: 16px;
    color: #adb0c0;
    font-weight: normal;
}

.payment-container button {
    background-color: #113670;
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-container button:hover {
    background-color: #0056b3;
}

@media (max-width: 1000px) {
    .payment-container .card-container {
        justify-content: center;
    }

    .payment-container .card {
        margin-bottom: 10px;
        width: 45%;
    }
}

@media (max-width: 600px) {
    .payment-container .card {
        width: 90%;
        height: 60vh;
        padding-bottom: 5%;
    }

    .payment-container .text-gray {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .payment-container {
        padding-inline: 5px;
    }

    .payment-container .text-gray {
        font-size: 10px;
    }

    .payment-container .card .badges span {
        font-size: 12px;
    }
}

@media (max-width: 450px) {
  .payment-container .card {
    height: 45vh;
  }
}

@media (max-width: 400px) {
    .payment-container .card {
        height: 50vh;
        padding-bottom: 10%;
    }
    }

@media (max-width: 380px) {
  .payment-container .card {
    height: 60vh;
    padding-bottom: 10%;
  }
}

@media (max-width: 350px) {
    .payment-container .card {
      height: 45vh;
      padding-right: 0px;
    }

  .payment-container .text-gray {
    font-size: 8px;
  }

  .payment-container .card h2 {
    font-size: 24px;
  }

  .payment-container .card p {
    font-size: 14px;
  }

  .payment-container .card .price {
    font-size: 26px;
  }

  .payment-container .card-container {
    padding-bottom: 0px;
  }

  .payment-container {
    margin-bottom: 10%;
  }
}
