.about-page .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.about-page .about,
.about-page .contact {
    margin-inline: 1%;
    border-radius: 8px;
    box-shadow: 0px 2px 4px #0000001a;
    padding: 25px;
}

.about-page .about h2,
.about-page .contact h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5%;
}

.about-page .about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.about-page .about h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.about-page .about p {
    font-size: 14px;
}

.about-page .about .bold-para {
    font-weight: 600;
    font-size: 15px;
}

.about-page .about .lower-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-page .about .lower-div .first-div p {
    margin: 0px;
    font-size: 14px;
}

.about-page .contact {
    padding-inline: 5%;
}

.about-page .contact .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fbfbfb;
    outline: none;
    color: #7e7e7e;
}


@media (max-width: 768px) {
    .about-page .container {
        flex-direction: column;
    }

    .about-page .about,
    .about-page .contact {
        width: 100% !important;
        margin-bottom: 5%;
    }
}

@media (max-width: 560px) {
    .about-page .container {
        padding-inline: 4%;
    }
}