.admin-login-component {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f8f9fd;
}

.admin-login-form {
    width: 30%;
    height: 70%;
    padding: 32px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px #0000001a;
    text-align: center;
    animation: fadeIn 1s ease;
}

.admin-login-heading {
    font-size: 28px;
    font-weight: 300;
    margin: 16px 0px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.admin-login-form input {
    width: 95%;
    margin: 4% 0;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.admin-login-form input:focus {
    border-color: #007bff;
    outline: none;
}

.admin-login-form button {
    width: 95%;
    height: 40px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-login-form button:hover {
    background-color: #0056b3;
}

.admin-password-input {
    position: relative;
}

.admin-password-input .icon-div {
    position: absolute;
    top: 30%;
    right: 7%;
    cursor: pointer;
}

.account-para {
    margin: 0px;
}

.navigate-btn {
    color: #007bff;
}

.admin-login-component .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: #1089ff;
    border-radius: 50%;
    font-size: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.login-wrap .icon span {
    color: #fff;
}

@media (max-width: 1280px) {
    .admin-login-form {
        height: 60%;
        padding: 5% 2%;
    }
}

@media (max-width: 1024px) {
    .admin-login-form {
        height: 70%;
        padding: 5% 2%;
    }
}

@media (max-width: 912px) and (min-height : 700px) {
    .admin-login-form {
        width: 60%;
        height: fit-content;
    }

    .admin-login-form input {
        padding: 17px;
    }

    .admin-login-form h1 {
        margin: 25px 0px;
    }

    .admin-login-form button {
        padding: 17px 0px;
    }

    .account-para {
        margin: 10px 0px;
    }
}

@media (max-width: 912px) and (max-height : 699px) {
    .admin-login-form {
        width: 50%;
    }

    .admin-login-form h1 {
        margin: 0px;
    }

    .admin-login-form input {
        padding: 8px;
    }

    .admin-login-form button {
        margin: 10px 0px;
    }
}

@media (max-width: 540px) {
    .admin-login-component .icon {
        width: 110px;
        height: 110px;
    }

    .admin-login-component .icon .fa-lg {
        font-size: 50px;
    }

    .admin-login-form {
        width: 80%;
    }

    .admin-login-form input {
        padding: 12px;
    }

    .admin-login-form button {
        margin: 20px 0px;
    }
}

@media (max-width: 500px) {
    .admin-login-component {
        align-items: flex-start;
        margin-top: 15vh;
    }
}

@media (max-width: 400px) {
    .admin-login-form {
        width: 85%;
    }

    .admin-login-form button {
        padding: 12px 0px;
    }

    .admin-login-component .icon {
        width: 90px;
        height: 90px;
    }

    .admin-login-component .icon .fa-lg {
        font-size: 40px;
    }
}

@media (max-width: 350px) {
    .admin-login-form {
        height: 65%;
    }
}