.navbar {
    background-color: #007bff;
    display: flex;
    align-items: center;
    padding: 30px;
    color: white;
    position: sticky;
}

.navbar-brand {
    display: none;
}

.navbar-toggler {
    background: none;
    border: none;
}

.navbar-brand button {
    margin-inline: 20px;
}

.navbar .dropdown {
    position: absolute;
    right: 30px;
    z-index: 1;
}

.navbar .dropdown-toggler {
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: none !important;
}

.navbar .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 4px 8px #0000001a;
    border-radius: 4px;
    display: none;
    min-width: 150px;
    border: 1px solid #d2d5d8;
    font-size: 14px;
}

.navbar .dropdown-menu.show {
    display: block;
}

.navbar .dropdown-item {
    padding: 8px 16px;
    text-decoration: none;
    color: black;
    display: block;
}

.info-item {
    padding: 8px 16px 4px;
    color: #757e85;
    cursor: default;
}

.navbar .dropdown-divider {
    border-bottom: 1px solid #e3e3e3;
}

.navbar .dropdown-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

@media (max-width: 992px) {
    .navbar {
        padding: 16px;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
    }

    .navbar-collapse {
        flex-direction: column;
        align-items: flex-start;
        display: none;
        width: 100%;
    }

    .navbar-collapse.show {
        display: flex;
    }

    .navbar-nav {
        flex-direction: column;
        width: 100%;
    }

    .nav-item {
        width: 100%;
        text-align: left;
        margin: 8px 0;
    }
}