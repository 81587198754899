.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #979797;
  padding: 16px;
  width: 100vw;
  padding-inline: 5%;
  position: relative;
  z-index: 99;
  box-shadow: 0px -5px 25px 0px #00000033;
}

.top-bar-div {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-bar-div .account-link {
  cursor: default;
}

.logo {
  font-family: "Georgia", serif;
  color: #000000;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.links li {
  margin-right: 40px;
}

.links a {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  text-decoration: none;
}

.search-bar {
  width: 20%;
  display: flex;
  align-items: center;
  position: relative;
}

.search-bar input {
  padding: 6px 8px;
  background: #f2f2f2;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  color: #868686;
  border: 1px solid #dddddd;
  outline: none;
}

.search-bar button {
  background-color: #113670;
  color: #fff;
  border: none;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.account-dropdown {
  position: relative;
  margin-left: 20px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: none;
  box-shadow: 0px 2px 4px #0000001a;
  z-index: 100;
}

.dropdown-menu.open {
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: black;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.search-results {
  background: white;
  height: max-content;
  width: 100vw;
  left: 0;
  top: 100%;
  border-radius: 6px;
  overflow: hidden;
  overflow-y: auto;
  padding: 1% 10%;
  border-bottom: 1px solid #ccc;
}

.search-results ul {
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
}

.search-results .title-item {
  border: 1px solid #eee;
  background: #f0f0f0;
  padding: 10px;
  font-weight: 600;
}

.search-results .material-type {
  padding: 10px;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.search-results span {
  display: block;
  font-size: 14px;
  padding: 0px 10px;
}

.search-results .close-button {
  background: none;
  border: none;
  float: right;
  font-size: 24px;
}

.search-results-dropdown {
  top: 100%;
  height: max-content;
  max-height: 30vh;
  width: 12vw;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.search-results-dropdown ul {
  list-style: none;
  padding: 0;
}

.search-results-dropdown li {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-results-dropdown li:hover {
  background: #f0f0f0;
}

.top-bar .view-registry .modal-overlay{
  z-index: 200 !important;
}

@media (max-width: 1300px) {
  .top-bar-div {
    width: 70%;
  }

  .search-results-dropdown {
    width: 15vw;
  }
}

@media (max-width: 1100px) {
  .logo {
    font-size: 20px;
  }

  .top-bar-div {
    width: 70%;
    margin-left: 5%;
  }

  .search-bar {
    width: 30%;
  }
  .links li {
    margin-right: 30px;
  }

  .search-results-dropdown {
    width: 20vw;
  }
}

@media (max-width: 1000px) {
  .links li {
    margin-right: 20px;
  }
}

@media (max-width: 880px) {
  .links li {
    margin-right: 10px;
  }

  .search-bar input {
    width: 100%;
  }

  .search-bar .search-bar button {
    padding: 6px 10px;
  }

  .top-bar-div {
    width: 75%;
    margin-left: 0%;
  }
}

@media (max-width: 600px) {
  .top-bar-div {
    width: 100%;
  }

  .logo {
    font-size: 20px;
  }

  .top-bar {
    display: block;
  }

  .account-dropdown {
    margin-left: 5px;
  }

  .dropdown-menu {
    right: 1%;
  }

  .search-bar {
    width: 75%;
  }

  .search-results-dropdown {
    width: 40vw;
  }
}

@media (max-width: 450px) {
  .top-bar-div {
    flex-direction: column;
  }
  .top-bar-div .links {
    margin: 20px 0px;
  }
  .search-bar {
    width: 100%;
  }
  .search-results-dropdown {
    width: 60vw;
  }
}

@media (max-width: 350px) {
  .top-bar-div .links ul li,
  .top-bar-div .links ul li a {
    font-size: 12px;
  }
}
