.home-page .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: 5%;
}

.home-page .card {
    background-color: white;
    border-radius: 8px;
    margin: 10px;
    width: 100%;
    padding: 10px;
    box-shadow: 0 4px 8px #0000001a;
}

.home-page .card-content {
    display: flex;
    width: 100%;
}

.home-page .card-image {
    width: 50%;
    background-color: #c0ddff;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
}

.home-page .card-image img{
    width: 100%;
    height: 100%;
}

.home-page .card-text {
    width: 50%;
    padding: 5% 3%;
}

.home-page .card h2 {
    margin: 0px 0px 16px;
    font-size: 20px;
    font-weight: 600;
}

.home-page .card li {
    margin: 5px 0;
    font-size: 14px;
}

.home-page .card-two-text p {
    margin: 5px 0;
    font-size: 14px;
}

.home-page .card button {
    background-color: #113670;
    /* border: none; */
    border-radius: 5px;
    padding: 12px 0px;
    width: 50%;
    cursor: pointer;
    margin-top: 12px;
    transition: background-color 0.3s;
}

.home-page .card button:hover {
    background-color: #0e2d5e;
}

@media (max-width: 850px) {

    .home-page .card-text {
        padding: 1% 3%;
    }
}

@media (max-width: 600px) {

    .home-page .card-image {
        display: none;
    }

    .home-page .card-text {
        width: 100%;
    }
}