.form-page-main-div {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
}

.form-page {
    display: flex;
    width: 100vw;
}

.left-component {
    width: 33vw;
    background: #c3edff;
    display: flex;
    padding-inline: 5% 3%;
    flex-direction: column;
    padding-top: 15vh;
    position: relative;
}

.left-component h3 {
    font-size: 18px;
    letter-spacing: 1.3px;
    margin: 1% 0%;
}

.left-component p {
    font-size: 9px;
    margin-top: 2%;
    letter-spacing: .5px
}

.illustration {
    width: 400px;
    margin-left: -10%;
    margin-top: 7%;
}

.dot-img {
    width: 100px;
    margin-left: -3%;
    margin-top: 10%;
}

.reserved-rights {
    position: absolute;
    bottom: 17px;
}

.right-component {
    width: 67vw;
    height: max-content;
    display: flex;
    padding-inline: 10%;
    flex-direction: column;
    padding-top: 10vh;
}

.right-component h4 {
    margin-top: 2%;
}

.login-form {
    height: 70vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login-form h3 {
    font-size: 24px;
}

.login-form h4 {
    font-size: 16px;
    font-weight: 600;
}

.signup-form {
    height: max-content;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signup-form h4 {
    font-size: 18px;
    margin-top: 2vh;
}

.signup-form .fields {
    margin-top: 2vh;
    height: 100%;
}

.registry-input-div,
.input-div {
    display: flex;
    flex-direction: column;
}

.input-div:not(:first-child) {
    margin-top: 3%;
}

.registry-input-div label,
.input-div label {
    font-size: 14px;
    color: #8d8d8d;
}

.registry-input-div textarea,
.registry-input-div input,
.input-div input {
    height: 50px;
    border: 1px solid #8d8d8d;
    border-radius: 7px;
    padding-inline: 5%;
    margin: 10px 0px;
    font-size: 14px;
}

.password-input {
    position: relative;
}

.password-input input {
    width: 100%;
}

.password-input .icon-div {
    position: absolute;
    top: 30%;
    right: 3%;
}

.password-input .reset-icon-div {
    background: none;
    border: none;
}

.required-asterisk {
    color: #ff0000;
}

.remember-forgot {
    display: flex;
    justify-content: space-between;
    padding-inline: 2%;
    margin-top: 3%;
    font-size: 15px;
    cursor: default;
}

.remember-forgot input {
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.remember-forgot a {
    font-size: 15px;
    color: #000000;
    text-decoration: none;
}

.checkbox-div {
    display: flex;
    color: #8d8d8d;
}

.checkbox-div p {
    margin-left: 10px;
}

.forgot-btn,
.login-btn {
    background: #003770;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    height: 35px;
    cursor: pointer;
}

.signup-form .login-btn {
    height: 50px;
    margin-top: 0%;
}

.signup-form .forgot-btn {
    height: 40px;
}

.sign-in-btn {
    color: #003770;
    font-weight: 700;
    background: #e4f0ff;
    font-size: 16px;
    border: 2px solid #abd1f9;
    border-radius: 5px;
    height: 35px;
}

.account-para {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8d8d8d;
    padding: 10px 0px 30px;
}

.account-para .navigate-btn {
    color: #001623;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}

.payment {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    background: #f2f2f2;
}

.payment img {
    height: fit-content;
}

.admin-profile-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-profile-form form {
    width: 50%;
}

.accordion {
    width: 50%;
    padding-bottom: 5vh;
    box-shadow: none !important;
}

.admin-profile-form .login-btn {
    padding-inline: 10px;
    margin: 0px 0px 10px;
    width: max-content;
}

@media (max-width: 1280px) {

    .left-component {
        width: 45%;
    }

    .left-component p {
        font-size: 13px;
    }

    .illustration {
        width: 500px;
        margin-left: -15%;
    }

    .dot-img {
        width: 150px;
    }

    .left-component h3 {
        font-size: 26px;
    }

    .right-component {
        width: 55%;
    }

    .login-form {
        height: 58%;
    }

}

@media (max-width: 1024px) {

    .left-component {
        padding-inline: 4%;
        width: 33%;
    }

    .left-component p {
        font-size: 7px;
    }

    .left-component h3 {
        font-size: 16px;
    }

    .illustration {
        width: 300px;
    }

    .dot-img{
        width: 100px;
        margin-top: 20%;
    }

    .right-component {
        width: 66%;
    }

    .login-form {
        height: 86%;
    }

}

@media (max-width: 912px) {
    .left-component {
        display: none;
    }

    .right-component {
        width: 100vw;
    }

    .login-form {
        width: 100%;
    }

    .signup-form {
        width: 100%;
    }

    .account-para {
        width: 100%;
    }
}

@media (max-width: 912px) and (min-height: 1000px) {
    .login-form {
        height: 58%;
    }
}

@media (max-width : 600px) {
    .admin-profile-form form {
        width: 75%;
    }
}

@media (max-width: 430px) {
    .login-form {
        height: 60%;
    }

    .signup-form {
        width: 100%;
    }

    .admin-profile-form form {
        width: 85%;
    }
}

@media (max-width: 375px) {
    .login-form {
        height: 75%;
    }

    .checkbox-div p {
        margin-left: 5px;
        font-size: 14px;
    }

    .remember-forgot a {
        font-size: 13px;
    }

    .admin-profile-form form {
        width: 90%;
    }
}

@media (max-width: 353px) {
    .login-form {
        height: 65%;
    }

    .account-para {
        margin-top: 7%;
    }
}

@media (max-width: 320px) {

    .right-component {
        padding-inline: 5%;
    }

    .login-form {
        height: 75%;
    }
}

@media (max-height: 450px) {
    .login-form {
        height: max-content;
    }
}