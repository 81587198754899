.account-page .page-main-div {
    padding-inline: 5%;
    align-items: flex-end;
    justify-content: flex-start;
}

.account-table {
    width: 100%;
    overflow: hidden;
    height: max-content;
    background-color: #ffffff;
    padding: 20px 0px 0px;
    border-radius: 8px;
    box-shadow: 0 2px 10px #0000001a;
}

.account-table h2 {
    margin-bottom: 20px;
    font-size: 20px;
    padding: 0px 16px;
    font-weight: 700;
}

.account-table table {
    width: 100%;
    border-collapse: collapse;
}

.account-table th,
.account-table td {
    padding: 12px;
    text-align: left;
    border-top: 1px solid #ddd;
}

.account-table th {
    background-color: #f2f2f2;
}

.account-table .view-button,
.account-table .register-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.account-table .view-button {
    background-color: #199A54;
    color: white;
}

.account-table .view-button:hover {
    background-color: #168f4b;
}

.account-table .view-button-danger {
    margin-left: 10px;
    background-color: #de2d2d;
    color: white;
}

.account-table .view-button-danger:hover {
    background-color: #be2626;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    padding: 1% 5%;
}

.pagination button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    color: #113670;
    cursor: pointer;
    margin: 0 5px;
}

.pagination button.in-active {
    color: #ddd;
}

.pagination button.active {
    border: 1px solid #113670;
}

.pagination button:hover {
    background-color: #f0f0f0;
}

.pagination button:disabled {
    cursor: not-allowed;
    color: #A5A5A5;
}
@media (max-width: 850px) {

    .account-table table {
        width: 100%;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
}