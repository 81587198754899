.page {
  background-color: #f5f5f5 !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.page-main-div {
  width: 100%;
  min-height: 100vh;
  padding: 3% 0px;
}

.add-document-page .page-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: max-content;
}

.add-document-form {
  width: 50%;
  background-color: #ffffff;
  padding: 20px;
  padding: 20px 5%;
  border-radius: 8px;
  box-shadow: 0px 2px 4px #0000001a;
}

.add-document-form h2 {
  font-size: 18px;
  font-weight: 600;
}

.add-document-form p {
  font-size: 14px;
  margin-bottom: 5%;
}

.form-group {
  margin-bottom: 16px;
}

.form-group .required {
  color: #dc3545;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #868686;
}

.file-input {
  display: none;
}

.file-upload-container,
.select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fbfbfb;
  outline: none;
  color: #7e7e7e;
}

.select {
  cursor: pointer;
}

.file-upload-container {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-text {
  background-color: #ffffff;
  color: #000000;
  padding: 5px 20px;
  border: none;
  font-size: 14px;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 2px 4px #0000001a;
}

.submit-button {
  background-color: #113670;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.go-back {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.go-back .arrow-span {
  margin-right: 10px;
}

.back-button {
  background-color: transparent;
  color: #333;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 16px;
  font-size: 14px;
}

@media (max-width: 470px) {
  .add-document-form {
    width: 90%;
  }
}
