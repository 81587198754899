.main-footer{
    position: relative;
    box-shadow: 0px 5px 25px 0px #00000033;
    z-index: 99;
}

.footer {
    background-color: #ffffff;
    bottom: 0;
    width: 100vw;
}

.footer .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.footer-title {
    font-family: 'Georgia', serif;
    font-size: 24px;
    font-weight: 600;
}

.footer-links {
    margin-bottom: 15px;
}

.footer-links a {
    font-size: 14px;
    margin: 0 15px;
    text-decoration: none;
    color: #000000;
}

.footer-contact {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 5% 0px;
    border-top: 1px solid #dee2e6;
}


@media (max-width: 600px) {
    .footer-contact {
        font-size: 12px;
    }

    .footer-links a {
        font-size: 12px;
    }
}

@media (max-width: 450px) {
    .footer-contact {
        font-size: 10px;
    }

    .footer-links a {
        font-size: 10px;
    }
}