.main-verify-div {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0px;
}

.main-verify-div p {
    font-size: 20px;
}

.checkmark {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #146c43;
    font-size: 100px;
    cursor: default;
    margin: 30px 0px;
    color: #ffffff;
}

.crossmark {
    background-color: #bb2d3b;
}

@media (max-width: 430px) {
    .main-verify-div h1 {
        font-size: 24px;
    }

    .main-verify-div {
        padding: 100px 0px;
    }
}

@media (max-width: 390px) {
    .main-verify-div h1 {
        font-size: 22px;
    }

    .checkmark {
        width: 150px;
        height: 150px;
        font-size: 75px;
    }
}

@media (max-width: 320px) {
    .main-verify-div h1 {
        font-size: 18px;
    }
}