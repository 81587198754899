.view-registry .modal-overlay {
  background-color: #00000099;
}

.view-registry .modal-content {
  width: 40%;
  transform: translateY(-20px);
  animation: slideIn 0.3s forwards;
}

@keyframes slideIn {
  to {
    transform: translateY(0);
  }
}

.view-registry .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.view-registry .modal-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.view-registry .close-button {
  background: none;
  border: none;
  font-size: 34px;
  cursor: pointer;
  color: #999;
  transition: color 0.2s ease;
}

.view-registry .close-button:hover {
  color: #555;
}

.view-registry .registry-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.view-registry .modal-overlay h5 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.view-registry .registry-input-div {
  display: flex;
  flex-direction: column;
}

.view-registry .registry-input-div label {
  font-size: 14px;
  color: #444;
  margin-bottom: 5px;
}

.view-registry .registry-input-div textarea {
  height: 20vh;
}

.view-registry .registry-input-div input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.2s ease;
}

.view-registry .registry-input-div input:disabled {
  background-color: #f1f1f1;
  color: #888;
}

.view-registry .registry-input-div input:hover {
  border-color: #bbb;
}

.view-registry .modal-footer {
  gap: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 20px;
}

.pdf-buttons {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  gap: 10px;
}

.btn-save,
.button-close {
  border: none;
  width: 100px;
  height: 50px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 1100px) {
  .view-reg-form {
    overflow-x: hidden;
  }

  .view-registry .modal-content {
    width: 60%;
  }
}

@media (max-width: 950px) {
  .view-registry .modal-content {
    width: 75%;
  }

  .view-registry .modal-overlay h5 {
    font-size: 18px;
  }
}

@media (max-width: 615px) {
  .view-registry .registry-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 391px) {
  .view-registry .modal-content {
    width: 90%;
  }

  .view-registry .modal-overlay h5 {
    font-size: 14px;
    font-weight: 600;
  }

  .view-registry .modal-footer button {
    font-size: 12px;
  }
}

@media (min-width: 1900px) {
  .view-registry .registry-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
