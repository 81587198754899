.sidebar {
    padding: 16px;
    background-color: #ffffff;
    width: 17vw;
    height: 100vh;
    z-index: 10;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-btn {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar-nav {
    padding-top: 16px;
    margin-top: 8px;
}

.sidebar-nav ul {
    padding-left: 0px;
}

.nav-item {
    list-style-type: none;
    margin-bottom: 8px;
}

.nav-item span {
    margin-inline: 10px;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 12px;
    text-decoration: none;
    color: #6c757d;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover,
.nav-link-active {
    color: #007bff;
    background-color: #e9ecef;
}

.nav-link i {
    margin-right: 8px;
}

@media (max-width: 992px) {
    .sidebar {
        position: absolute;
        left: -100vw;
        transition: left 0.3s;
        width: 25vw;
    }

    .sidebar.showSidebar {
        left: 0;
    }

    .close-btn {
        display: block;
    }
}

@media (max-width: 600px) {
    .sidebar {
        width: 40vw;
    }

}

@media (max-width: 430px) {
    .sidebar {
        width: 60vw;
    }
}

@media (max-width: 390px) {
    .sidebar {
        width: 100vw;
    }
}