.single-form-page .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.single-form-page .card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px #0000001a;
    padding: 10px 20px 5%;
    text-align: center;
    width: 33%;
    height: fit-content;
}

.single-form-page .icon-container {
    background-color: #E8EDF6;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
}

.single-form-page .icon {
    width: 30px;
    height: 30px;
}

.single-form-page .text-container h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}