.dashboard {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: #ecf5f9;
}

.main-dashboard .header-div {
    height: 10%;
}

.main-dashboard .table-div {
    height: 90%;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 3%;
}


.main-dashboard .table-div::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
}

.main-dashboard .table-div::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 4px;
}

.main-dashboard {
    width: 83vw;
}

@media (max-width: 992px) {
    .main-dashboard {
        width: 100vw;
    }

}